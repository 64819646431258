<template>
    <div>
        <SettingPhoneCommon :data="configGetDetailDocuments" />
    </div>
</template>

<script>
import SettingPhoneCommon from '@/views/pages/setting-phone/common/main.vue';

import { masterMethods, authComputed } from '@/state/helpers';
import { WORKFLOW_TYPE } from '@/views/pages/setting-phone/constants';
import { setDataConfigForDocument } from '@/views/pages/setting-phone/index';
export default {
    components: {
        SettingPhoneCommon
    },
    data() {
        return {
            type: this.$route?.query?.type,
            hasPhoneSetting: null,
            workflowType: WORKFLOW_TYPE,
            configGetDetailDocuments: {
                campaign: null,
                sale_staff: '',
                isEdit: false,
                isRegister: false,
                person_in_charge: '',
                customer_team_pic: '',
                phone_pic: '',
                inforCampaign: null,
                workflow_type: null,
                campaign_master_order_scopes: [],
                campaign_master_order_scope_partner: []
            },
            isLoading: false
        };
    },
    computed: {
        ...authComputed
    },
    methods: {
        ...masterMethods,

        async handleGetDetailDocument() {
            try {
                const response = await this.getDetailDocuments({ id: this.$route.query.id });
                let dataRespones = response.data;
                this.hasPhoneSetting = dataRespones.phone_setting;
                this.configGetDetailDocuments = setDataConfigForDocument(dataRespones, this.currentUser);
            } catch (err) {
                console.log('err', err);
            }
        }
    },
    mounted() {
        this.handleGetDetailDocument();
    }
};
</script>

<style lang="scss" scoped></style>
