<template>
    <div>
        <b-modal
            v-if="inputForm.data_chat"
            v-model="config.isShowModal"
            size="xl"
            no-close-on-backdrop
            :id="config.code + '-modal'"
            :title="`${$t('sheduleFile.detailWork')} [ ${config?.dataWork?.label ?? ''} ]`"
            scrollable
            @hidden="handleCloseModal"
        >
            <div style="height: 75vh; position: relative">
                <div class="col-lg-12" v-if="!isLoading">
                    <div class="form-group row">
                        <label class="col-sm-3" for="nameChat"
                            >{{ $t('settingPhone.popUpSectionWork.nameChat') }}
                            <span class="text-danger">*</span>
                        </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`multi-select-room-chat`"
                                :value.sync="inputForm.room_chat"
                                :options="inputForm.optionsRoomChat"
                                :config.sync="inputForm.configRoomChat"
                                :disabled="inputForm.isRoomChat || inputForm.history"
                                @select="handleSelectRoomChat"
                            />
                            <div v-if="inputForm.configRoomChat.error" class="invalid-feedback">
                                <span>{{ inputForm.errorRoomChat }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3" for="sourceChat">{{ $t('settingPhone.popUpSectionWork.sourceChat') }} </label>
                        <div class="col-sm-9">
                            <input v-model="inputForm.source_chat.name" id="sourceChat" type="text" class="form-control disabled_cursor" disabled />
                        </div>
                    </div>

                    <div class="form-group row">
                        <label class="col-sm-3" for="selectSend">{{ $t('settingPhone.popUpSectionWork.selectSend') }} </label>
                        <div class="col-sm-9">
                            <Multiselect
                                :id="`multi-select-type-send`"
                                :value.sync="inputForm.type_send"
                                :options="optionsSend"
                                :config.sync="inputForm.configTypeSend"
                                :disabled="inputForm.sendBoth || inputForm.history"
                            />
                        </div>
                    </div>

                    <!-- tabs -->
                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-model="activeTab">
                        <b-tab @click="handleTabClick(0)" class="border-0" :disabled="inputForm.type_send.id === 2">
                            <template v-slot:title>
                                <span class="">{{ $t('settingPhone.popUpSectionWork.message') }}</span>
                            </template>

                            <template>
                                <div class="form-group row">
                                    <label class="col-sm-3" for="to">{{ $t('settingPhone.popUpSectionWork.image') }} </label>
                                    <div class="col-sm-9">
                                        <UploadFile
                                            :title="$t('btn.uploadFile')"
                                            :id="$t('settingPhone.popUpSectionWork.image')"
                                            :typeUpload="'image'"
                                            :disabled="!inputForm.history"
                                            :config="configUpload"
                                            @dataFile="handleFileInput"
                                        />
                                        <div v-if="fileInfo.originName" class="mt-2 d-flex align-items-center">
                                            {{ fileInfo.originName }}
                                            <span
                                                class="ml-2"
                                                style="cursor: pointer; font-size: 20px"
                                                @click="
                                                    () => {
                                                        fileInfo.originName = '';
                                                        inputForm.messages.file = null;
                                                    }
                                                "
                                                ><i class="ri-close-line" style="vertical-align: bottom"></i
                                            ></span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-3" for="to"
                                        >{{ $t('settingPhone.popUpSectionWork.to') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-9">
                                        <Multiselect
                                            :id="`multi-select-send-to-messages`"
                                            :value.sync="inputForm.messages.to"
                                            :options="optionSendTo"
                                            :config.sync="inputForm.messages.configSendTo"
                                            :disabled="inputForm.history"
                                        />
                                        <div v-if="inputForm.messages.configSendTo.error" class="invalid-feedback">
                                            <span>{{ inputForm.messages.errorSendTo }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-3" for="from"
                                        >{{ $t('settingPhone.popUpSectionWork.content') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-12">
                                        <textarea
                                            rows="15"
                                            :id="`editor-${inputForm.child_id}-message`"
                                            v-model="inputForm.messages.content"
                                            class="form-control"
                                            :class="inputForm.messages.errorContent ? 'is-invalid' : ''"
                                            :disabled="inputForm.history"
                                        />
                                        <div v-if="inputForm.messages.errorContent" class="invalid-feedback">
                                            <span>{{ inputForm.messages.errorContent }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </b-tab>

                        <b-tab @click="handleTabClick(1)" class="border-0" :disabled="inputForm.type_send.id === 1">
                            <template v-slot:title>
                                <span class="">{{ $t('settingPhone.popUpSectionWork.task') }}</span>
                            </template>

                            <template>
                                <div class="form-group row">
                                    <label class="col-sm-3" for="to"
                                        >{{ $t('settingPhone.popUpSectionWork.timeTask') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-9">
                                        <DateTime
                                            id="select-task-date-time"
                                            :full_date.sync="inputForm.task.date_task"
                                            :full_time.sync="inputForm.task.time_task"
                                            :config.sync="inputForm.configTaskDateTime"
                                            :disabled="inputForm.history"
                                            @change="handleRecivedChangeDate"
                                        >
                                            <span v-if="inputForm.configTaskDateTime.error" class="invalid-feedback d-block">{{
                                                inputForm.configTaskDateTime.errorTimeTask
                                            }}</span>
                                        </DateTime>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-3" for="to"
                                        >{{ $t('settingPhone.popUpSectionWork.to') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-9">
                                        <Multiselect
                                            :id="`multi-select-send-to-task`"
                                            :value.sync="inputForm.task.to"
                                            :options="optionSendTo"
                                            :config.sync="inputForm.task.configSendTo"
                                            :disabled="inputForm.history"
                                        />
                                        <div v-if="inputForm.task.configSendTo.error" class="invalid-feedback">
                                            <span>{{ inputForm.task.errorSendTo }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-sm-3" for="from"
                                        >{{ $t('settingPhone.popUpSectionWork.content') }}
                                        <span class="text-danger">*</span>
                                    </label>
                                    <div class="col-sm-12">
                                        <textarea
                                            rows="15"
                                            :id="`editor-${inputForm.child_id}-task`"
                                            v-model="inputForm.task.content"
                                            class="form-control"
                                            :class="inputForm.task.errorContent ? 'is-invalid' : ''"
                                            :disabled="inputForm.history"
                                        />
                                        <div v-if="inputForm.task.errorContent" class="invalid-feedback">
                                            <span>{{ inputForm.task.errorContent }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </b-tab>
                    </b-tabs>
                </div>
                <div v-else style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%)">
                    <LoadingIcon />
                </div>
            </div>

            <template #modal-footer>
                <div class="text-center flex-fill">
                    <button type="button" class="btn btn-light mr-3" @click="handleCloseModal">
                        {{ $t('btn.cancel') }}
                    </button>
                    <button v-if="!inputForm.history" type="button" class="btn btn-primary ml-3" @click="handleSave">
                        {{ $t('btn.send') }}
                    </button>
                </div>
            </template>
        </b-modal>

        <!-- Modal Confirm Excute Step -->
        <ModalCommon v-else id="Modal Confirm Excute Step" :config="configModalCommon" @save="handleSaveStepEmpty()">
            <div class="col-sm-12">
                <h5>
                    {{ configModalCommon.textConfirm }}
                </h5>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import moment from 'moment';
import i18n from '@/i18n';
import Multiselect from '@/components/Multiselect/main.vue';
import UploadFile from '@/components/Uploads/Files/main.vue';
import DateTime from '@/components/DateCustoms/DateTime/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';

import { settingPhoneMethods, campaignMethods } from '@/state/helpers';
import { INIT_FROM, OPTIONS_SEND } from './constanst';
import { handleValidate } from './index';
import { setDataInternal, setDataPartner } from './setData';
import { WORKFLOW_TYPE } from '@/views/pages/setting-phone/constants';
import { getUserInRoom } from '@/views/pages/setting-phone/index';
import { showMessage } from '@/utils/messages.js';
import { tranferShortCode } from '@/utils/format';
import { state } from '@/state/modules/authfack.js';

import ModalCommon from '@/components/Modal/main.vue';

export default {
    name: 'PopupSectionWorkPhone',
    components: {
        Multiselect,
        UploadFile,
        DateTime,
        LoadingIcon,
        ModalCommon
    },
    props: {
        config: {
            type: Object,
            default: () => {
                return {
                    isShowModal: false,
                    code: '',
                    dataWork: []
                };
            }
        }
    },
    data() {
        return {
            inputForm: INIT_FROM,
            optionsSend: OPTIONS_SEND,
            optionSendTo: [],
            listSendTo: [],
            activeTab: 0,
            obj: null,
            fileInfo: {
                originName: ''
            },
            hours: Array.from({ length: 24 }).map((_, i) => i + 0),
            isOpen: false,
            formatDateSend: 'YYYY-MM-DD HH:mm',
            isLoading: false,
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                size: 'md',
                textConfirm: '',
                titleBtn: this.$t('btn.ok')
            },
            configUpload: {
                allowedSize: 5120,
                allowedExtension: ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/bmp']
            }
        };
    },
    methods: {
        ...settingPhoneMethods,
        ...campaignMethods,

        handleRecivedChangeDate(newDate) {
            this.inputForm.shortCode.desired_release_date = this.formatDateToChinese(newDate);
            // lấy ra task của step 発番依頼 (4) || ★ガイダンス録音依頼 (7)
            if (this.inputForm.child_id == 4 || this.inputForm.child_id == 7) {
                if (this.inputForm.workflow_type == WORKFLOW_TYPE.INTERNAL) {
                    setDataInternal(this.inputForm);
                } else if (this.inputForm.workflow_type == WORKFLOW_TYPE.PARTNER) {
                    setDataPartner(this.inputForm);
                } else {
                    setDataInternal(this.inputForm);
                }
            }
        },

        formatDateToChinese(time) {
            if (time) {
                const date = new Date(time);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                const hours = moment(time).format('HH:mm') ?? '';

                if (time.includes(' ')) {
                    this.inputForm.task.time_task = `${hours}`;
                    this.inputForm.task.date_task = `${year}-${month >= 10 ? month : '0' + month}-${day}`;
                    return `${year} 年 ${month} 月 ${day} 日 ${hours}`;
                } else {
                    this.inputForm.task.date_task = `${year}-${month >= 10 ? month : '0' + month}-${day}`;
                    return `${year} 年 ${month} 月 ${day} 日 `;
                }
            }
            return '';
        },

        handleChangeFrom(value, type) {
            if (type === 'minute') {
                this.isOpen = false;
            }
        },

        handleSetDataDefault() {
            this.inputForm.type_send = this.optionsSend[0];
            this.inputForm.messages.to = [];
            this.inputForm.task.to = [];
            this.inputForm.task.date = '';
            this.inputForm.task.time = '';
            this.inputForm.task.date_task = '';
            this.inputForm.task.time_task = '';
            this.inputForm.task.errorContent = '';
            this.inputForm.messages.errorContent = '';
            this.inputForm = INIT_FROM;
            this.inputForm.configTaskDateTime.error = false;
        },

        handleCloseModal() {
            this.config.isShowModal = false;
            this.handleSetDataDefault();
            this.$emit('closeModal');
        },

        handleTabClick(index) {
            this.activeTab = index;
        },

        handleFileInput(receivedFile) {
            this.fileInfo.originName = receivedFile?.origin_name ?? '';
            this.inputForm.messages.file = receivedFile?.id ?? '';
        },

        handleSelectRoomChat(infoRoom) {
            this.optionSendTo = this.listSendTo.find((item) => item.room_id == infoRoom.id)?.users ?? [];
            this.handleSetSendToUserDefault(this.inputForm);
        },

        handleSetSendToUserDefault(valRecived) {
            const userDefault = this.optionSendTo.find((item) => item.id === this.inputForm.person_in_charge.id);
            const objConditionUser = {
                userMessLength: valRecived.data_chat.configMess.to_users.length > 0,
                userTaskLength: valRecived.data_chat.configTask.to_users.length > 0,
                personInchargeMess: valRecived.data_chat.configMess.to_person_in_charge && userDefault,
                personInchargeTask: valRecived.data_chat.configTask.to_person_in_charge && userDefault,
                inChargeOfDistributionMess: valRecived.data_chat.configMess.to_in_charge_of_distribution,
                inChargeOfDistributionTask: valRecived.data_chat.configTask.to_in_charge_of_distribution,
                phoneSettingConstructionMess: valRecived.data_chat.configMess.to_phone_setting_construction,
                phoneSettingConstructionTask: valRecived.data_chat.configTask.to_phone_setting_construction
            };
            this.inputForm.messages.to = [];
            this.inputForm.task.to = [];

            if (objConditionUser.userMessLength) {
                const listSelectUserDefault = this.optionSendTo.filter((item) => valRecived.data_chat.configMess.to_users.includes(item.id));
                this.inputForm.messages.to.push(...listSelectUserDefault);
            }

            if (objConditionUser.userTaskLength) {
                const listSelectUserDefault = this.optionSendTo.filter((item) => valRecived.data_chat.configTask.to_users.includes(item.id));
                this.inputForm.task.to.push(...listSelectUserDefault);
            }

            if (objConditionUser.personInchargeMess) {
                this.inputForm.messages.to.push(userDefault);
            }

            if (objConditionUser.personInchargeTask) {
                this.inputForm.task.to.push(userDefault);
            }

            if (objConditionUser.inChargeOfDistributionMess) {
                for (let dtbM of this.optionSendTo) {
                    if (dtbM.phone_setting_type.map((pst) => pst.type).includes(3)) {
                        this.inputForm.messages.to.push(dtbM);
                    }
                }
            }

            if (objConditionUser.inChargeOfDistributionTask) {
                for (let dtbT of this.optionSendTo) {
                    if (dtbT.phone_setting_type.map((pst) => pst.type).includes(3)) {
                        this.inputForm.task.to.push(dtbT);
                    }
                }
            }

            if (objConditionUser.phoneSettingConstructionMess) {
                for (let pscM of this.optionSendTo) {
                    if (pscM.phone_setting_type.map((pst) => pst.type).includes(2)) {
                        this.inputForm.messages.to.push(pscM);
                    }
                }
            }

            if (objConditionUser.phoneSettingConstructionTask) {
                for (let pscT of this.optionSendTo) {
                    if (pscT.phone_setting_type.map((pst) => pst.type).includes(2)) {
                        this.inputForm.task.to.push(pscT);
                    }
                }
            }
        },

        async handleSave() {
            let resultValidate = handleValidate(this.inputForm);
            if (resultValidate.errorTab) {
                if (resultValidate.errorTab === 'message') {
                    showMessage(422, this.$bvToast, this.$t('settingPhone.popUpSectionWork.errorTabTask'));
                } else {
                    showMessage(422, this.$bvToast, this.$t('settingPhone.popUpSectionWork.errorTabMessage'));
                }
            }

            let objSend = {
                id_phone_setting: this.inputForm.id_phone_setting,
                id_step_parent: this.inputForm.child_id,
                chatwork: {
                    ...(this.inputForm.step_child && { step_child: this.inputForm.step_child }),
                    room: {
                        id: this.inputForm.room_chat?.id ?? null,
                        name: this.inputForm.room_chat?.name ?? ''
                    },
                    message: {
                        image_id: this.inputForm.messages.file ?? null,
                        from: {
                            id: this.inputForm.source_chat.id ?? null,
                            name: this.inputForm.source_chat.name ?? '',
                            account_id: Number(this.inputForm.source_chat.account_id) ?? ''
                        },
                        to: this.inputForm.messages.to ?? [],
                        body: this.inputForm.messages.content
                    },
                    task: {
                        from: {
                            id: this.inputForm.source_chat.id ?? null,
                            name: this.inputForm.source_chat.name ?? '',
                            account_id: Number(this.inputForm.source_chat.account_id) ?? ''
                        },
                        to: this.inputForm.task.to ?? [],
                        body: this.inputForm.task.content,
                        due_date: this.inputForm.task.time_task
                            ? moment(this.inputForm.task.date_task + ' ' + this.inputForm.task.time_task, this.formatDateSend).format(
                                  this.formatDateSend
                              )
                            : this.inputForm.task.date_task,
                        has_time: this.inputForm.task.time_task ? true : false
                    }
                }
            };

            if (this.inputForm.type_send.id === 2) delete objSend.chatwork.message;
            if (this.inputForm.type_send.id === 1) delete objSend.chatwork.task;
            if (!resultValidate.check) return;
            this.$emit('refreshData');
            try {
                const res = await this.updateStepWorkflow(objSend);
                if (res.code === 200) {
                    this.handleCloseModal();
                    this.handleSetDataDefault();
                } else {
                    showMessage(res.code, this.$bvToast, i18n.t(res.message));
                }
            } catch (err) {
                console.log('err', err);
            }
        },

        async handleGetUserInRoom(valRecived) {
            let payload = {
                campaign_id: this.$route.query?.id ?? null,
                room_id: this.inputForm.room_chat?.id ?? null
            };
            this.isLoading = true;
            try {
                const res = await getUserInRoom(payload);
                if (res.code !== 200) {
                    showMessage(res.code, this.$bvToast, i18n.t(`${res.message}`));
                    return;
                }
                if (!valRecived.data_chat.room_chat.id) {
                    this.inputForm.optionsRoomChat =
                        res?.data?.map((itemRes) => ({
                            id: itemRes.room_id ? Number(itemRes.room_id) : null,
                            name: itemRes.room_name
                        })) ?? [];
                }

                if (this.inputForm.room_chat?.id) {
                    this.optionSendTo = res?.data ?? [];
                } else {
                    this.listSendTo = res?.data;
                }

                // set send to user default
                this.handleSetSendToUserDefault(valRecived);
            } catch (err) {
                showMessage(422, this.$bvToast, i18n.t('chatwork_token.exists'));
                console.log('err', err);
            } finally {
                this.isLoading = false;
            }
        },

        async handleSaveStepEmpty() {
            let objSend = {
                id_phone_setting: this.inputForm.id_phone_setting,
                id_step_parent: this.inputForm.child_id,
                chatwork: {
                    ...(this.inputForm.step_child && { step_child: this.inputForm.step_child })
                }
            };
            this.$emit('refreshData');
            try {
                const res = await this.updateStepWorkflow(objSend);
                if (res.code !== 200) {
                    showMessage(res.code, this.$bvToast, i18n.t(res.message));
                }
            } catch (err) {
                console.log('err', err);
            }
        }
    },
    watch: {
        'config.dataWork': {
            handler: function (valRecived) {
                this.inputForm.room_chat = null;
                this.inputForm.messages.content = '';
                this.inputForm.task.content = '';

                this.activeTab = 0;
                this.inputForm.messages.to = valRecived?.data_chat?.messageTo ?? [];
                this.inputForm.task.to = valRecived?.data_chat?.taskTo ?? [];
                this.inputForm = {
                    ...this.inputForm,
                    ...valRecived,
                    ...valRecived?.data_chat
                };
                this.inputForm.source_chat = state?.user ?? { name: '' };

                if (!valRecived.data_chat) {
                    const replacementsConfirm = {
                        電話の設定_電話番号: this.inputForm.shortCode.pre_order_phone_numbers
                            ? this.inputForm.shortCode.pre_order_phone_numbers
                            : this.inputForm.shortCode.reservation
                              ? this.inputForm.shortCode.reservation
                              : ''
                    };

                    this.configModalCommon.model = true;
                    this.configModalCommon.title = valRecived?.label ?? '';
                    this.configModalCommon.textConfirm = tranferShortCode(valRecived.confirm_msg ?? '', replacementsConfirm)
                        ? tranferShortCode(valRecived.confirm_msg ?? '', replacementsConfirm)
                        : this.$t('settingPhone.popUpSectionWork.confirmStep');
                    return;
                }
                this.inputForm.shortCode.desired_release_date = this.formatDateToChinese(
                    this.inputForm?.data_chat?.due_date ?? new Date().toISOString().slice(0, 10)
                );

                if (valRecived.workflow_type == WORKFLOW_TYPE.INTERNAL) {
                    setDataInternal(this.inputForm);
                } else if (valRecived.workflow_type == WORKFLOW_TYPE.PARTNER) {
                    setDataPartner(this.inputForm);
                } else {
                    setDataInternal(this.inputForm);
                }

                if (valRecived?.data_chat?.details.message && valRecived?.data_chat?.details.task) {
                    this.inputForm.type_send = this.optionsSend[0];
                } else if (valRecived?.data_chat?.details.message) {
                    this.inputForm.type_send = this.optionsSend[1];
                    this.inputForm.sendBoth = true;
                } else if (valRecived?.data_chat?.details.task) {
                    this.inputForm.type_send = this.optionsSend[2];
                    this.inputForm.sendBoth = true;
                } else {
                    this.inputForm.sendBoth = false;
                }

                if (valRecived.history) {
                    this.inputForm.room_chat = valRecived.data_chat.room_chat ?? null;
                    this.inputForm.messages.content = valRecived.data_chat.messageLog?.body ?? '';
                    this.inputForm.task.content = valRecived.data_chat.taskLog?.body ?? '';
                    this.inputForm.source_chat = valRecived.confirmed_by;
                    return;
                }

                this.handleGetUserInRoom(valRecived);
            },
            deep: true
        }
    }
};
</script>

<style lang="scss">
.time__task--error {
    .mx-input-wrapper {
        border: 1px solid #ff3d60;
        border-radius: 5px;
    }
}
.custom--time__task {
    .mx-input-wrapper {
        .mx-input:disabled {
            color: #505d69;
        }
    }
}
</style>
