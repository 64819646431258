import i18n from '@/i18n';

const WORKFLOW_TYPE = {
    COMMON: 0,
    INTERNAL: 1,
    PARTNER: 2
};

const STATUS_WORKFLOW = [
    {
        id: 0,
        name: 'pending'
    },
    {
        id: 1,
        name: 'done'
    }
];

const ACTION_TYPE = [
    {
        id: 1,
        type: 0,
        label: i18n.t('settingPhone.personInChargePrimarily'),
        backgroundColor: '#fce5cd',
        user: [],
        partner_pic: []
    },
    {
        id: 2,
        type: 1,
        label: i18n.t('settingPhone.customerTeam'),
        backgroundColor: '#c9daf8',
        user: [],
        partner_pic: []
    },
    {
        id: 3,
        type: 2,
        label: i18n.t('settingPhone.personInChargeSettingPhone'),
        backgroundColor: '#d9ead3',
        user: [],
        partner_pic: []
    },
    {
        id: 4,
        type: 3,
        label: i18n.t('settingPhone.sorting'),
        backgroundColor: '#d4c3ff',
        user: [],
        partner_pic: []
    }
];

const PHONE_NUMBER_TYPE = [
    {
        id: 1,
        name: i18n.t('settingPhone.phoneNumberType1')
    },
    {
        id: 2,
        name: i18n.t('settingPhone.phoneNumberType2')
    }
];

const YEAR_END_HOLIDAY_TYPE = [
    {
        id: 1,
        name: i18n.t('settingPhone.yearEndHoliday1')
    },
    {
        id: 2,
        name: i18n.t('settingPhone.yearEndHoliday2')
    }
];

const FREE_PHONE_NUMBER_TYPE = [
    {
        id: 1,
        name: i18n.t('settingPhone.freePhoneNumberType1')
    },
    {
        id: 2,
        name: i18n.t('settingPhone.freePhoneNumberType2')
    },
    {
        id: 3,
        name: i18n.t('settingPhone.freePhoneNumberType3')
    }
];

const FREE_PHONE_NUMBER_NOTIFICATION = [
    {
        id: 0,
        name: i18n.t('settingPhone.freePhoneNumberNotification1')
    },
    {
        id: 1,
        name: i18n.t('settingPhone.freePhoneNumberNotification2')
    }
];

const HAS_CONNECTION = [
    {
        id: 0,
        name: i18n.t('settingPhone.hasconnectionNo')
    },
    {
        id: 1,
        name: i18n.t('settingPhone.hasconnectionYes')
    }
];

const CONFIG_LIST_USER_AFTER_SEND_TO = {
    multiple: true,
    trackBy: 'id',
    label: 'name',
    error: false,
    isRemove: true
};

const OPTIONS_PARTNERS_DEFAULT = [
    {
        name: 'コールセンター（ボンズ）',
        step_default: [4, 8, 13, 18, 21]
    },
    {
        name: 'コールセンター香川（UT）',
        step_default: [5, 9, 14, 19, 22]
    },
    {
        name: 'コールセンター（日本物流）',
        step_default: [6, 10, 15, 20, 23]
    }
];

const OPTIONS_WORKFLOWS = [
    {
        id: 1,
        name: i18n.t('document.internal')
    },
    {
        id: 2,
        name: i18n.t('document.partner')
    }
];

const SETTING_CONTENT_TYPES = [
    {
        id: 1,
        name: '話中設定'
    },
    {
        id: 2,
        name: '終了ガイダンス設定'
    },
    {
        id: 3,
        name: 'その他（→自由記述欄）'
    }
];

export {
    PHONE_NUMBER_TYPE,
    FREE_PHONE_NUMBER_TYPE,
    YEAR_END_HOLIDAY_TYPE,
    FREE_PHONE_NUMBER_NOTIFICATION,
    HAS_CONNECTION,
    CONFIG_LIST_USER_AFTER_SEND_TO,
    STATUS_WORKFLOW,
    ACTION_TYPE,
    WORKFLOW_TYPE,
    OPTIONS_PARTNERS_DEFAULT,
    OPTIONS_WORKFLOWS,
    SETTING_CONTENT_TYPES
};
