<template>
    <div>
        <h4 class="font-weight-bold mb-4">
            {{ $t('settingPhone.other') }}
        </h4>
        <!-- inDoorFree -->
        <div class="form-group">
            <label class="col-sm-3" for="inDoorFree">{{ $t('settingPhone.inDoorFree') }} </label>
            <div class="col-sm-9">
                <textarea
                    rows="5"
                    v-model="initForm.note"
                    type="text"
                    class="form-control"
                    :class="{
                        disabled_cursor: disabled
                    }"
                    :disabled="disabled"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StartFive',
    props: {
        data: {
            type: Object,
            default: () => ({})
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isSeenFirst: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            initForm: null
        };
    },
    methods: {
        mapDataObject(newDataInitForm) {
            return {
                note: newDataInitForm.note ?? ''
            };
        }
    },
    watch: {
        data: {
            handler(valReceived) {
                this.initForm = valReceived;
            },
            immediate: true
        },
        initForm: {
            handler(newDataInitForm) {
                let dataForm = this.mapDataObject(newDataInitForm) ?? null;
                this.$emit('updateData', dataForm);
            },
            deep: true
        },
        isSeenFirst: {
            handler(val) {
                if (val) {
                    let dataForm = this.mapDataObject(this.initForm) ?? null;
                    this.$emit('updateData', dataForm);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
